<template>
  <div class="list-item row">
    <div>
      <slot name="label"></slot>
    </div>
    <div>
      <slot name="value"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItemComplex",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/List";
</style>
